<template>
  <div>
    <h3>{{ this.$t('my-reservations') }}</h3>

    <b-table
      v-if="reservations.length > 0"
      :items="reservations"
      :fields="fields"
      responsive
    >
      <template v-slot:cell(Status)="data">
        <span
          >{{
            reservationStatus(
              data.item.ReservationStatus,
              data.item.WarehouseID
            )
          }}<br />
          <small>{{
            paymentStatus({
              isPayed: !data.item.Balance,
              onInvoice: data.item.OnInvoice
            })
          }}</small></span
        >
      </template>
      <template v-slot:cell(WarehouseID)="data">
        <span>{{
          $store.getters['warehouseName'](data.item.WarehouseID)
        }}</span>
      </template>
      <template v-slot:cell(ReservationID)="data">
        <router-link
          :to="{
            name: 'reservation',
            params: { reservationID: data.item.ReservationOrderID }
          }"
          >#{{ data.item.ReservationOrderID }}</router-link
        >
      </template>
      <template v-slot:cell(TotalIncVAT)="data">
        <div class="form-group text-right">
          &euro;
          {{
            (Math.round(data.item.TotalIncVAT * 100) / 100)
              .toFixed(2)
              .replace('.', ',')
          }}
        </div>
      </template>

      <template v-slot:cell(DateTimeBusinessStart)="data">
        {{ format(data.item.DateTimeBusinessStart) }}
      </template>
      <template v-slot:cell(DateTimeExpectedEnd)="data">
        {{ format(data.item.DateTimeExpectedEnd) }}
      </template>
      <template v-slot:cell(ReportID)="data">
        <span
          v-if="data.item.ReportID"
          @click="
            getReport({
              reportID: data.item.ReportID,
              reservationOrderID: data.item.ReservationOrderID,
              reportFileName: data.item.ReportFileName,
              attachmentToken: data.item.AttachmentToken
            })
          "
          class="text-primary"
          style="cursor:pointer"
          variant="link"
          >{{ data.item.ReportFileName }}</span
        >
      </template>
      <template v-slot:cell(ContactFirstName)="data">
        {{ data.item.ContactFirstName }} {{ data.item.ContactLastName }}
      </template>
    </b-table>
    <p v-else>
      {{ this.$t('no-reservations-found') }}
    </p>
  </div>
</template>

<script>
import {
  getReservations,
  getReservationReport
} from '@/services/ReservationService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export default {
  data() {
    return {
      reservations: [],
      fields: [
        {
          key: 'ReservationID',
          label: this.$t('request')
        },
        {
          key: 'Status',
          label: this.$t('status')
        },
        {
          key: 'TotalIncVAT',
          label: this.$t('total'),
          class: 'text-right no-break '
        },
        {
          key: 'WarehouseID',
          label: this.$t('location')
        },
        {
          key: 'DateTimeBusinessStart',
          label: this.$t('retrieval-date')
        },
        {
          key: 'DateTimeExpectedEnd',
          label: this.$t('return-date')
        },
        // {
        //   key: 'ReportID',
        //   label: this.$t('attachment')
        // },

        {
          key: 'ReservationOrderID',
          label: '',
          class: 'd-none'
        },
        {
          key: 'AttachmentToken',
          label: '',
          class: 'd-none'
        },
        {
          key: 'OnInvoice',
          class: 'd-none'
        },
        {
          key: 'IsPayed',
          class: 'd-none'
        }
        // {
        //   key: 'ContactFirstName',
        //   label: this.$t('reserved-by')
        // }
      ]
    }
  },
  created: async function() {
    this.reservations = await getReservations()
    this.$store.commit('setReservations', { reservations: this.reservations })
  },
  methods: {
    paymentStatus: function({ isPayed, onInvoice }) {
      if (isPayed) return this.$t('paid')
      if (onInvoice) return this.$t('by-invoice')
      return this.$t('not-payed')
    },
    reservationStatus: function(status) {
      if (status === 'Active' || status === 'Open' || status === 'Actief')
        return this.$t('requested')

      if (status === 'Order') return this.$t('requested')

      if (status === 'Pick' || status === 'Picked')
        return this.$t('in-progress')

      if (status === 'Pending') return this.$t('rented')

      if (status === 'Closed') return this.$t('returned')

      if (status === 'Cancelled' || status === 'Expired')
        return this.$t('cancelled')

      if (status === 'Rejected') return this.$t('declined')

      return this.$t('requested')
    },
    format(date) {
      return dayjs(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    getReport: async function({
      reportID,
      reservationOrderID,
      reportFileName,
      attachmentToken
    }) {
      await getReservationReport({
        reservationOrderID: reservationOrderID,
        reportID: reportID,
        attachmentToken,
        fileName: `${reservationOrderID}-${reportFileName}`
      })
    }
  }
}
</script>

<style scss></style>
