<template>
  <b-card class="card-user mt-4">
    <b-button
      v-if="canEdit && user.ContactID !== this.$store.state.contactID"
      @click="remove()"
      variant="link"
      class="float-right"
    >
      <font-awesome-icon icon="trash" />
    </b-button>

    <p>
      {{ user.FirstName }} {{ user.LastName }}<br />
      <small
        ><a :href="`mailto:${user.Email}`">{{ user.Email }}</a></small
      >
    </p>
    <router-link
      v-if="canEdit"
      :to="{
        name: 'editSub',
        params: { username: user.UserName, lang: this.$i18n.locale }
      }"
      class="btn btn-primary"
      >{{ $t('contactCard.changeDetails') }}</router-link
    >
    <b-button
      v-if="canEdit && user.ContactID != contactID"
      @click="promote(user.Email, user.ContactID)"
      variant="link"
      class="float-right"
    >
      <small> {{ $t('contactCard.makePrimaryUser') }} </small>
    </b-button></b-card
  >
</template>

<script>
import { deleteSub, transferOwnership } from '@/services/AuthorizationService'
export default {
  props: {
    user: {
      required: true,
      type: Object,
      default: function() {
        return {}
      }
    },
    contactID: {
      required: true,
      type: Number,
      default: function() {
        return 0
      }
    },
    canEdit: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  methods: {
    promote: async function() {
      let confirmed = confirm(
        `${this.$t('contactCard.confirm', {
          firstName: this.user.FirstName,
          lastName: this.user.LastName
        })}`
      )

      if (confirmed === true) {
        await transferOwnership({
          subUserName: this.user.Email,
          subUserContactID: this.user.ContactID
        })
        await this.$store.commit('unsetCart')
        await this.$store.commit('unsetUser', {
          reason: 'loggedOut=true'
        })
      }
    },
    remove: async function() {
      let confirmed = confirm(
        this.$t('contact-delete-prompt', {
          firstName: this.user.FirstName,
          lastName: this.user.LastName
        })
      )
      if (confirmed === true) {
        await deleteSub({
          subUserName: this.user.Email,
          subUserContactID: this.user.ContactID
        })
        this.$emit('updateContacts')
      }
    }
  }
}
</script>

<style></style>
