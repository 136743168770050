var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v(_vm._s(this.$t('my-reservations')))]),(_vm.reservations.length > 0)?_c('b-table',{attrs:{"items":_vm.reservations,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"cell(Status)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.reservationStatus( data.item.ReservationStatus, data.item.WarehouseID ))),_c('br'),_c('small',[_vm._v(_vm._s(_vm.paymentStatus({ isPayed: !data.item.Balance, onInvoice: data.item.OnInvoice })))])])]}},{key:"cell(WarehouseID)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.$store.getters['warehouseName'](data.item.WarehouseID)))])]}},{key:"cell(ReservationID)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'reservation',
          params: { reservationID: data.item.ReservationOrderID }
        }}},[_vm._v("#"+_vm._s(data.item.ReservationOrderID))])]}},{key:"cell(TotalIncVAT)",fn:function(data){return [_c('div',{staticClass:"form-group text-right"},[_vm._v(" € "+_vm._s((Math.round(data.item.TotalIncVAT * 100) / 100) .toFixed(2) .replace('.', ','))+" ")])]}},{key:"cell(DateTimeBusinessStart)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.format(data.item.DateTimeBusinessStart))+" ")]}},{key:"cell(DateTimeExpectedEnd)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.format(data.item.DateTimeExpectedEnd))+" ")]}},{key:"cell(ReportID)",fn:function(data){return [(data.item.ReportID)?_c('span',{staticClass:"text-primary",staticStyle:{"cursor":"pointer"},attrs:{"variant":"link"},on:{"click":function($event){return _vm.getReport({
            reportID: data.item.ReportID,
            reservationOrderID: data.item.ReservationOrderID,
            reportFileName: data.item.ReportFileName,
            attachmentToken: data.item.AttachmentToken
          })}}},[_vm._v(_vm._s(data.item.ReportFileName))]):_vm._e()]}},{key:"cell(ContactFirstName)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.ContactFirstName)+" "+_vm._s(data.item.ContactLastName)+" ")]}}],null,false,2212385499)}):_c('p',[_vm._v(" "+_vm._s(this.$t('no-reservations-found'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }