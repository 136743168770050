<template>
  <b-container>
    <bread-crumbs :currentpage="$t('myaccount')" />

    <b-row>
      <b-col cols="12">
        <h1 class="text-center">{{ $t('myaccount') }}</h1>
      </b-col>
      <b-col class="d-none d-md-block">
        <div class="h-100">
          <p>
            {{ $t('account-generalDescription') }}
            <a :href="`/${this.$i18n.locale}`" class="float-right">{{
              $t('backToCatalog')
            }}</a>
          </p>
        </div>
      </b-col>
    </b-row>

    <b-alert v-if="alertInfoMessage" show variant="danger">{{
      alertInfoMessage
    }}</b-alert>
    <div class="account-tabs">
      <b-tabs
        pills
        card
        nav-wrapper-class="col-sm-12 col-md-12"
        content-class="col-xs-12 col-md-12"
      >
        <b-tab :title="$t('account-title-my-details')" active>
          <User :user="user" />
        </b-tab>
        <b-tab :title="$t('account-title-reservations')">
          <Reservations />
        </b-tab>
        <b-tab :title="$t('account-title-contacts')">
          <Contact />
        </b-tab>
      </b-tabs>
    </div>
  </b-container>
</template>
<script>
import User from '@/views/Account/User'
import Reservations from '@/views/Account/Reservations'
import Contact from '@/views/Account/Contact'

export default {
  components: {
    User,
    Reservations,
    Contact
  },
  metaInfo: {
    title: function() {
      return ''
    }
  },
  computed: {
    metaTitle: function() {
      return this.$t('header.myAccount')
    },
    user: function() {
      return this.$store.getters.user
    },
    alertInfoMessage: function() {
      if (this.$route.query.message) {
        this.notify({ message: this.$route.query.message })
        return
      }
      if (this.$route.query.infoMessage) return this.$route.query.infoMessage
      return null
    }
  },
  created: function() {
    if (this.$route.params.loggedIn) {
      this.notify({ message: this.$t('login-success') })
    }
    if (this.$route.params.message) {
      this.notify({ message: this.$route.params.message })
    }
  },

  methods: {
    notify({ message }) {
      this.$toasted.show(message)
    }
  }
}
</script>
<style lang="scss">
.account-tabs {
  min-height: 610px;
  .card-header {
    border: none !important;
  }
}
</style>
