<template>
  <div>
    <div>
      <h3>{{ $t('contact.title') }}</h3>
      <p>
        {{ $t('contact.description') }}
      </p>
      <b-row>
        <b-col v-if="isMainUser" cols="12">
          <router-link :to="{ name: 'newSub' }" class="btn btn-primary">{{
            $t('contact.addNewSub')
          }}</router-link>
        </b-col>
        <b-col
          v-for="user in subUsers"
          :key="user.UserName"
          md="6"
          xl="4"
          class="mt-4"
        >
          <contact-card
            :user="user"
            :contact-i-d="contactID"
            :can-edit="isMainUser"
            @updateContacts="updateContacts()"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { getSubs } from '@/services/AuthorizationService'
import ContactCard from './ContactCard'

export default {
  components: {
    'contact-card': ContactCard
  },
  data() {
    return {
      subUsers: []
    }
  },
  computed: {
    contactID: function() {
      return this.$store.getters.contactID
    },
    account: function() {
      return this.$store.getters.account
    },
    isMainUser: function() {
      if (this.$store.getters.account.RoleName === 'portal') {
        return false
      } else {
        return true
      }
    }
  },
  created: async function() {
    this.subUsers = await getSubs()
  },
  methods: {
    updateContacts: async function() {
      this.subUsers = await getSubs()
    }
  }
}
</script>
<style lang="scss" scoped>
.col {
  .card-user {
    height: 100%;
  }
}
</style>
